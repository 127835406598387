/**
 * Методы, связанные с пользователями системы
 */
import birt_axios from '@/api/birt'


const state = {}

const getters = {}

const mutations = {}

const actions = {

    createOwnersRegistry(store, {voting_id}) {
        const url = `/report/ownerReestr/pdf?p_voting_id=${voting_id}`
        birt_axios.get(url)
    },

    createPropOwnersRegistry(store, {property_id}) {
        const url = `/report/OwnerReestrByProperty/pdf?p_property_id=${property_id}`
        birt_axios.get(url)
    },

    createNoOwnerReestr(store, {voting_id}) {
        const url = `/report/noOwnerReestr/pdf?p_voting_id=${voting_id}`
        birt_axios.get(url)
    },

    createPaperDecisions(store, {voting_id}) {
        const url = `/report/ReportEmptyDecisionList/pdf?p_voting_id=${voting_id}`
        birt_axios.get(url)
    },


}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
