/**
 * Методы, связанные с голосованием
 */

import api from '../../api'

import {getField, updateField} from 'vuex-map-fields';

const state = {
    all: [],
    voting: {},
    statistics: {},
    decisions: {},
    users: [],
    documents: [],
    currentPage: 1,
}

const getters = {
    getField,

    all: (state) => state.all,
    users: (state) => state.users,
    voting: (state) => state.voting,
    voting_id: (state) => state.voting.votingId,
}

const mutations = {
    updateField,

    all: (state, all) => state.all = all,

    voting: (state, voting) => state.voting = voting,

    statistics: (state, statistics) => state.statistics = statistics,

    decisions: (state, decisions) => state.decisions = decisions,

    users: (state, users) => state.users = users,
}

const actions = {

    update({commit}) {
        return api.voting.getAll().then(data => {
            data = _.orderBy(data, 'start_dttm')
            commit('all', data);
            return data
        })
    },

    get_statistics({commit}, id) {
        return api.voting.getVoteStatistics(id).then(data => {
            console.log('data', data)
            commit('statistics', data);
            return data
        })
    },

    get_decisions({commit}, id) {
        return api.voting.getVoteDecisions(id).then(data => {
            commit('decisions', data);
            return data
        })
    },

    get_users({commit}, id) {
        return api.voting.getUsers(id).then(data => {
            commit('users', data);
            return data
        })
    },

    clear({commit}) {
        commit('voting', {
            property: {},
            voting_status: {},
            initiator: {},
            admin: {},
        });
    },

    load_by_id({commit}, id) {
        return api.voting.getById(id).then(data => {
            data.questions = data.questions.map(d => {
                d.show_stat = false;
                return d
            })
            commit('voting', data);
            return data
        })
    },

    load_docs({commit}, id) {
        return api.voting.getDocs(id).then(data => {
            commit('updateField', {path: 'documents', value: data});
            return data
        })
    },

    // Можно объединить с load_by_id
    save_voting_field(_, {id, field, value}) {
        return api.voting.saveField(id, field, value)
    },

    save_question_field(_, {voting_id, question_id, field, value}) {
        return api.voting.saveQuestionField(voting_id, question_id, field, value)
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
